import { useState } from "react";
import styles from "./index.module.scss";

import share from "assets/fans/share.svg";
//import { Trans } from "react-i18next";
import { useHook } from "app/hooks/common";
import CountdownComp from "./countdown";
import {
  CurrencyRateType,
  DirectusImage,
  LaunchPadNFT,
  NFTMinterQuery,
} from "app/core/common";
import Modal from "app/components/Modal";
import { Link } from "react-router-dom";
import Button from "app/components/button/button";
import LoginModal from "app/components/loginModal/LoginModal";
import { Trans } from "react-i18next";
import { useLogin } from "app/context/loginProvider";
import { textTruncate, truncate } from "app/helpers/helper";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment-timezone";


const LaunchpadForm = ({
  queryResult,
  launchpadNFT,
  currencyRate,
  launchpad_imageData,
  index,
  setPaymentModal,
  mintHandler,
  quantity,
  setQuantity,
}: {
  queryResult?: NFTMinterQuery;
  launchpadNFT?: LaunchPadNFT;
  currencyRate?: CurrencyRateType;
  launchpad_imageData?: any;
  index?: Number;
  setPaymentModal?: any;
  mintHandler?: any;
  quantity?: number;
  setQuantity?: any;
}) => {
  /* const { MintNft } = handleFansTransaction(); */
  const { toggleLoginModal, toggleSignupModal, toogleRegisterModal } =
    useLogin();
  const { address } = useHook();

  const [price, symbol] = launchpadNFT?.launchpad_type[
    Number(index)
  ]?.launchInfo?.mintPrice?.split(" ") || ["", "USDC"];
  const maxSupply = launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.maxSupply;
  /* const { balance } = useJunoTokenBalance(symbol); */

  /*   const contractAddr =
    launchpadNFT?.launchpad_type[Number(index)]?.launchInfo?.NFT; */


  const currentTime = moment.utc().valueOf();
  const genTime = (time: string) =>
    moment(time).add(moment(time).utcOffset(), "minutes").valueOf();

  const mintStarted = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.startDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.startTime
        }`
      )
  );
  const mintEnded = genTime(
    moment
      .utc()
      .format(
        `${launchpadNFT?.launchpad_type[Number(index)].launchInfo.endDate} ${
          launchpadNFT?.launchpad_type[Number(index)].launchInfo.endTime
        }`
      )
  );

  const beforeStartTime = currentTime < mintStarted;

  /* const [mintCount, setMintCount] = useState(1); */

  const EndDate = () => {
    return (
      <p
        className={styles.endDate}
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
        <Trans>endDate</Trans>:{" "}
        <b>
          <CountdownComp launchpadNFT={launchpadNFT} index={Number(index)} />
          {/* {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCDate()}{" "}
          {
            monthNames[
              new Date(
                launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
              ).getUTCMonth()
            ]
          }{" "}
          {new Date(
            launchpadNFT?.launchpad_type[Number(index)]?.launchInfo.endDate
          ).getUTCFullYear()} */}
        </b>
      </p>
    );
  };
  // function to increment the quantity of NFTs to mint
  const handleIncrement = () => {
    setQuantity((prevQuantity: number) => prevQuantity + 1);
  };
  // function to decrease the quantity of NFTs to mint
  const handleDecrement = () => {
    setQuantity((prevQuantity: number) =>
      prevQuantity > 1 ? prevQuantity - 1 : 1
    );
  };

  return (
    <div>
      <>
        <div className={styles.project_social}>
          {/* <ShareButton name={launchpadNFT?.project_name} icon={share} /> */}
        </div>

        <div className={styles.nft_view}>
          <div className={styles.header}>
            <div className={styles.desc}>
              <b
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html:
                    launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                      .name,
                }}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    launchpadNFT?.launchpad_type[Number(index)]?.fan_collection
                      .description,
                }}
              />
            </div>

            {/* Creator Info Section */}
            <div className={styles.artist}>
              <Link
                to={`/user/${
                  launchpadNFT?.artist.username ?? launchpadNFT?.artist.id
                }`}
                className={styles.profile}
              >
                <img
                  src={DirectusImage(launchpadNFT?.artist?.avatar?.id)}
                  alt="thumb"
                />
                <div>
                  <span>
                    <>CREATOR</>
                  </span>
                  <p>
                    {textTruncate(
                      launchpadNFT?.artist.display_name ??
                        launchpadNFT?.artist.first_name,
                      12
                    )}
                  </p>
                </div>
              </Link>
            </div>

            {/* Creator Info Section */}
            <div className={styles.artist}>
              <div>
                <span>
                  <>Collection Type</>
                </span>
                <p style={{ textTransform: "capitalize" }}>
                  <>{launchpadNFT?.collection_type}</>
                </p>
              </div>
              <div className={styles.divider} />
              <div>
                <span>
                  <>Total Quantity</>
                </span>
                <p style={{ textTransform: "capitalize" }}>
                  <>{maxSupply}</>
                </p>
              </div>
            </div>

            {/* End Date Section */}
            <div className={`${styles.mint_live} ${styles.mint_live_border}`}>
              <button className={styles.mint_countdown}>
                {launchpadNFT?.launch_tba ? (
                  <span className={styles.soon}>
                    <>Coming Soon</>
                  </span>
                ) : (
                  <span>
                    <CountdownComp
                      launchpadNFT={launchpadNFT}
                      index={Number(index)}
                    />
                  </span>
                )}
              </button>
            </div>

            {/* NFT Mint Section */}
            {!beforeStartTime ? (
              <>
                <div className={`${styles.mint_live} ${styles.mint_live_border}`}>
                  <div className={styles.price_infos}>
                    <div>
                      <p className={styles.subTitle}>
                        <Trans>price</Trans>
                      </p>
                      <p className={styles.price_value}>
                        {price == 0
                          ? `Free`
                          : `$${
                              parseFloat(Number(price).toLocaleString()) *
                              quantity
                            }`}
                      </p>
                    </div>

                    {!address ? null : (
                      <div className={styles.right}>
                        <p className={styles.subTitle}>Quantity</p>
                        <div className={styles.quantityContainer}>
                          <button
                            onClick={handleIncrement}
                            className={styles.incrementButton}
                            disabled={price == 0 || quantity >= maxSupply}
                          >
                            <AddIcon />
                          </button>

                          <span className={styles.quantity}>{quantity}</span>
                          <button
                            onClick={handleDecrement}
                            className={styles.decrementButton}
                            disabled={price == 0 || quantity === 1}
                          >
                            <RemoveIcon />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>

                  {mintEnded < currentTime ? (
                    <button className={styles.buy} disabled>
                      Mint Ended
                    </button>
                  ) : (
                    <>
                      {!address ? (
                        <>
                          <Button
                            onClick={() => {
                              toggleLoginModal();
                              toogleRegisterModal(true);
                            }}
                          >
                            Login
                          </Button>
                        </>
                      ) : (
                        <>
                          {/* <div className={styles.quantityContainer}>
                            <button
                              onClick={handleIncrement}
                              className={styles.incrementButton}
                            >
                              <AddIcon sx={{ color: blue[500] }} />
                            </button>

                            <span className={styles.quantity}>{quantity}</span>
                            <button
                              onClick={handleDecrement}
                              className={styles.decrementButton}
                            >
                              <RemoveIcon sx={{ color: blue[500] }} />
                            </button>
                          </div> */}
                          <button
                            disabled={
                              !address || launchpadNFT?.mint_status !== "active"
                            }
                            onClick={() =>
                              price == 0 ? mintHandler() : setPaymentModal(true)
                            }
                            className={styles.buy}
                          >
                            {launchpadNFT?.mint_status !== "active" ? (
                              <Trans>pausedCap</Trans>
                            ) : price == 0 ? (
                              <Trans>mintNowCap</Trans>
                            ) : (
                              <Trans>buyNowCap</Trans>
                            )}
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* <EndDate /> */}
              </>
            ) : (
              <div className={styles.mint_live}>
                <button
                  disabled
                  className={styles.buy}
                >
                  <span className={styles.soon}>
                    <>Starting Soon</>
                  </span>
                </button>
              </div>
            )}

            {/* <h3>
              {launchpadNFT?.launchpad_type[Number(index)].collection.name}
            </h3>
            <p
              className={styles.about}
              dangerouslySetInnerHTML={{
                __html:
                  launchpadNFT?.launchpad_type[Number(index)].collection
                    .description,
              }}
            /> */}

            {/* Warn user that NFT will be random */}
            {launchpadNFT?.launchpad_type[Number(index)]?.type_gallery?.length >
              1 && (
              <p style={{ textAlign: "center", color: "#677599" }}>
                <Trans>mintNote</Trans>
              </p>
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default LaunchpadForm;

import { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

interface mediaPlayerActions {
  showPlayerModal: boolean;
  pause: boolean;
  play: boolean;
  actualTrack: any;
  actualAlbumId: string;
  toggleMediaPlayerModal: (value: boolean, songId?: string) => void;
  tooglePlayPause: (value: boolean) => void;
  handleTrack: (trackInfo?: any) => void;
  handleTracks: (tracks: any[], currentIndex: number) => void;
  handleNextTrack: (trackInfo?: any) => void;
  handlePrevTrack: (trackInfo?: any) => void;
  handleSetAlbumId: (actualAlbumId?: any) => void;
}

export const MediaPlayerContext = createContext<mediaPlayerActions>({
  showPlayerModal: false,
  pause: false,
  play: false,
  actualTrack: null,
  actualAlbumId: null,
  handleTrack: () => {},
  handleTracks: () => {},
  toggleMediaPlayerModal: () => {},
  tooglePlayPause: () => {},
  handleNextTrack: () => {},
  handlePrevTrack: () => {},
  handleSetAlbumId: () => {},
});

export const MediaPlayerProvider = ({ children }) => {
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [play, setPlay] = useState(false);
  const [pause, setPause] = useState(false);
  const [playList, setPlayList] = useState([]);
  const [songIndex, setSongIndex] = useState<number>(null);
  const [actualTrack, setActualTrack] = useState(null);
  const [actualAlbumId, setActualAlbumId] = useState(null);

  const toggleMediaPlayerModal = (value: boolean) => {
    setShowPlayerModal(value);
    if (value === false) {
      setPause(true);
      setActualTrack("");
      Cookies.remove("mediaPlayerOpen");
      Cookies.remove("lastPlayedSong");
      Cookies.remove("AlbumlastPlayedSong");
    }
  };

  const handleSetAlbumId = (value) => {
    setActualAlbumId(value);
    Cookies.set("albumId", JSON.stringify(value), { expires: 7 });
  };

  const tooglePlayPause = (value: boolean) => {
    if (value === true) {
      setPlay(true);
      setPause(false);
    } else if (value === false) {
      setPlay(false);
      setPause(true);
    }
  };

  const handleTracks = (tracks?: [], currentIndex?: number) => {
    console.log(tracks, currentIndex);
    setSongIndex(currentIndex);
    setPlayList(tracks);
    const selectedTrack = tracks[currentIndex];
    // Set the actual track to the selected one
    setActualTrack(selectedTrack);

    // Set cookies with relevant information
    Cookies.set("mediaPlayerOpen", "true", { expires: 7 }); // expires in 7 days
    Cookies.set("currentIndex", currentIndex.toString(), { expires: 7 }); // expires in 7 days
    Cookies.set("tracks", JSON.stringify(tracks), {
      expires: 7,
    }); // expires in 7 days
  };

  const handleTrack = (trackInfo?: any) => {
    setPlayList([]);
    // Set the cookie when the modal is opened
    setActualTrack(trackInfo);
    Cookies.remove("currentIndex");
    Cookies.remove("tracks");
    Cookies.set("mediaPlayerOpen", "true", { expires: 7 }); // expires in 7 days
    Cookies.set("lastPlayedSong", JSON.stringify(trackInfo), { expires: 7 }); // expires in 7 days
  };

  const handlePrevTrack = () => {
    if (playList?.length > 1) {
      const actualIndex = songIndex;
      let prevTrack = actualIndex - 1;

      // Check if prevTrack is less than 0
      if (prevTrack < 0) {
        prevTrack = playList.length - 1; // Loop back to the last track
      }
      setSongIndex(prevTrack);
      const updateTrackinAlbum = playList[prevTrack];
      setActualTrack(updateTrackinAlbum);
      Cookies.set("currentIndex", prevTrack.toString(), { expires: 7 }); // expires in 7 days
    } else {
      return;
    }
  };

  const handleNextTrack = () => {
    if (playList?.length > 1) {
      const actualIndex = songIndex;
      let nextTrack = actualIndex + 1;

      // Check if nextTrack exceeds the playlist length
      if (nextTrack >= playList.length) {
        nextTrack = 0; // Loop back to the first track
      }

      setSongIndex(nextTrack);
      const updateTrackinAlbum = playList[nextTrack];
      setActualTrack(updateTrackinAlbum);
      Cookies.set("currentIndex", nextTrack.toString(), { expires: 7 }); // expires in 7 days
    } else {
      return;
    }
  };

  return (
    <MediaPlayerContext.Provider
      value={{
        showPlayerModal,
        actualTrack,
        actualAlbumId,
        handleSetAlbumId,
        toggleMediaPlayerModal,
        tooglePlayPause,
        play,
        pause,
        handleTrack,
        handleTracks,
        handleNextTrack,
        handlePrevTrack,
      }}
    >
      {children}
    </MediaPlayerContext.Provider>
  );
};

export const useMediaPlayer = (): mediaPlayerActions =>
  useContext(MediaPlayerContext);
